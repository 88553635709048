@import '../../less/mixins.less';

.button-size(@size) {
    .square(@size);
    min-width: @size;
}

.tb-button {
    &[data-icon="true"] {
        .button-size(36.5px);
        padding: 0;

        &[data-circular="true"] {
            border-radius: 50%;
        }

        &[data-size="small"] {
            .button-size(30.75px);
            font-size: 0.75rem;
        }

        &[data-size="large"] {
            .button-size(42.25px);
            font-size: 1.25rem;
        }
    }

    &[data-loading="true"] {

        .MuiButton-endIcon,
        .MuiButton-startIcon,
        .tb-button-children {
            opacity: 0;
        }
    }
}