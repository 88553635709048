@import '../less/sizes.less';
@import '../less/mixins.less';
@import '../less/colors.less';

.VisMenyKnapp {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: @toppmeny-icon-width;
    height: 100%;
    cursor: pointer;

    #device.desktop( {
            display: none;
        }

    );

    .KnappBar {
        display: block;
        background-color: @grey-base;
        margin: 6px 0;
        width: 22px;
        height: 2px;
        border-radius: 1px;
        transition: transform 0.4s ease;

        &.rotate-kb-1 {
            transform: translateY(4px) rotate(135deg);
        }

        &.rotate-kb-2 {
            transform: translateY(-4px) rotate(45deg);
        }
    }
}