html {
    .ModalWrapper {
        .IdleTimerModal {
            .ButtonWrapper {
                .ActionAndCloseWrapper {
                    justify-content: space-evenly;

                    .ActionButtonModal {
                        width: 45%;
                    }
                }
            }

            .timer-remaining {
                font-weight: bold;
            }
        }
    }
}