.KunngjoringsMelding {
    width: fit-content;
    max-width: 99%;
    margin: 1rem auto auto;

    .notification {
        .right {
            grid-template-columns: min-content min-content;
            .close {
                opacity: 1;
                grid-column: 2;
                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
}
