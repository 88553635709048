@import '../../less/sizes.less';
@import '../../less/mixins.less';

.ToppMenyItemLinkWrapper {
    color: inherit;
    text-decoration: inherit;

    &:hover,
    &:active,
    &:focus {
        color: inherit;
        text-decoration: inherit;
    }

    .ToppMenyItem {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        text-align: center;
        font-size: 24px;
        padding: 0 10px;
        cursor: pointer;

        .tekst {
            font-size: 16px;
            line-height: 20px;
            margin-left: 10px;
            margin-right: 10px;
            -webkit-font-smoothing: antialiased;
        }
    }
}

.PulldownWrapperNotifications {
    position: fixed;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 5px 5px;
    overflow: hidden;
    box-shadow: 0 1.5px 3px rgba(0, 0, 0, 0.3);
    right: 0;
    width: @venstremeny-width * 1.9;
    top: var(--toppmeny-current-height);
    opacity: 1;
    transition: top 0.2s ease, opacity 0.2s ease;

    #device.small({
    border-radius: 0;
    width: 100vw;
  });

    .PulldownInnhold {
        user-select: none;
        background-color: #fafaf6;

        #device.large({
      height: 100%;
    });

        .notification-heading {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            background-color: #29343d;
            height: 37px;

            .notification-heading-text {
                color: #ffffff;
                font-size: 16px;
                margin: 0 10px 0 0;
                padding: 5px;
                font-weight: bold;
            }

            .notification-heading-button {
                margin: 0;
                color: #ffffff;
            }
        }

        .toggle-buttons-wrapper {
            display: flex;
            justify-content: space-around;

            .toggle-button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50%;
                height: 25px;
                background-color: #fafaf6;
                color: #2d3a44;
                font-weight: bold;
                font-size: 16px;
                border: 1px solid #b9b9b9;
                border-right: none;
                border-left: none;
                border-radius: 0;
                cursor: pointer;
            }

            .toggle-button.active {
                background-color: #dcdcdc;
                box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.1);
            }
        }

        .notification-wrapper {
            display: flex;
            width: 100%;
            flex-direction: column;

            .empty-notification-list {
                display: flex;
                width: 100%;
                flex-direction: column;
            }
        }
    }

    .PulldownKnapper {
        user-select: none;
        height: calc(var(--toppmeny-current-height) * 0.618);
        background-color: #2d3a44;
        display: flex;
        flex-direction: row;
        padding: 0 10px;
        justify-content: space-between;
        align-items: center;

        .PulldownKnapp {
            color: #fafaf6;
            cursor: pointer;
        }
    }

    &.skjult {
        pointer-events: none;

        .PulldownInnhold {
            #device.large({
        height: 0;
      });
        }

        top: 0;
        opacity: 0;
    }
}
