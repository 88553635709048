@import '../../less/mixins.less';

.HelpDocument {
    background-color: white;

    .HelpExternalLink {
        i {
            margin-left: 5px
        }
    }

    .HelpContent {
        display: flex;

        .HelpMainContent {
            flex: 1 auto;
            padding: 20px;

            img {
                max-width: 100%;
            }

            iframe {
                max-width: 100%;
            }
        }

        .HelpSideContent {
            background-color: #eee;
            border-radius: 5px;
            height: 100%;
            padding: 10px;
        }

        #device.mobile({
            flex-direction: column;
        });
    }
}
