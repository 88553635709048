@import './less/sizes.less';
@import './less/mixins.less';
@import './less/colors.less';

:root {
    --toppmeny-height: @toppmeny-height;
    --toppmeny-mobile-height: @toppmeny-mobile-height;
    --toppmeny-current-height: var(--toppmeny-height, @toppmeny-height);

    #device.mobile( {
            --toppmeny-current-height: var(--toppmeny-mobile-height, @toppmeny-mobile-height);
        }

    );
}

html,
body {
    height: 100%;
}

html {
    font-size: 16px !important;
}

body {
    -webkit-font-smoothing: antialiased;
    color: @grey-base;
    font-family: 'Open Sans', sans-serif;
}

#navmeny {
    z-index: 50;

    @media print {
        display: none;
    }
}

#hovedcontainer-wrapper {
    -webkit-overflow-scrolling: touch;
    height: 100%;
}

// Class for use by fixed elements in other projects
.menu-pad {
    right: 0;
    bottom: 0;
    top: var(--toppmeny-current-height);

    #device.mobile( {
            left: 0;
        }

    );

    #device.desktop( {
            left: 60px;
        }

    );

    #device.desktop_menu-pinned( {
            padding-left: @venstremeny-width;
        }

    );
}

#hovedcontainer {
    padding-bottom: 100px;
    padding-top: var(--toppmeny-current-height);

    #device.desktop( {
            padding-left: 60px;
        }

    );

    #device.desktop_menu-pinned( {
            padding-left: @venstremeny-width;
        }

    );

    #hovedinnhold {
        padding-top: 20px;
    }
}

#hubspot-messages-iframe-container.widget-align-right.tb-hubspot-container {
    right: 40px !important;

    @media print {
        display: none !important;
    }
}