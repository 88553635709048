@import '../less/sizes.less';
@import '../less/mixins.less';
@import '../less/colors.less';

.SearchWrapper {
    font-size: 16px;
    position: relative;
    min-height: var(--toppmeny-current-height);
    display: flex;
    flex-direction: column;

    #device.small( {
            flex: 1;
            padding-right: @toppmeny-margin-lr;
        }

    );
}

.Search {
    text-align: center;
    font-size: 24px;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;

    > a {
        height: 100%;
        color: inherit;
        text-decoration: inherit;
    }

    .SearchIcon {
        cursor: pointer;
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
        width: @toppmeny-icon-width;
    }

    input {
        border: none;
        border-radius: 0;
        border-bottom: 2px solid @grey-base;
        background: none;
        margin: 0;
        padding: 0;
        opacity: 0;
        width: 0;
        font-size: 16px;
        height: calc(var(--toppmeny-mobile-height, @toppmeny-mobile-height) - 20);
        transition: all 0.3s ease;

        &:focus {
            outline: none;
        }
    }

    &:not(.soker) {
        input {
            transition-delay: 0.2s;
        }
    }

    #device.small( {
            input {
                flex: 1;
                min-width: 0;
            }
        }

    );

    &.soker {
        input {
            opacity: 1;
            border-bottom: 2px solid @grey-base;
        }

        #device.large( {
                display: flex;
                flex-direction: row;
                align-items: center;

                input {
                    width: @venstremeny-width;
                    padding: 0 10px;
                }
            }

        );
    }
}
