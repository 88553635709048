@import '../less/mixins.less';
@import '../less/colors.less';

@dark: @grey-base;
@dark2: #00070e;
@darker: mix(@dark, @dark2, 50%);
@mark: 5px 0 0 #3878c7 inset;

.VenstreMenyItem {
    user-select: none;
    width: 100%;
    background-color: transparent;
    position: relative;
    transition: 0.3s background-color ease;

    &.current {
        .VenstreMenyItemMain {
            box-shadow: @mark;
        }
    }

    a {
        color: inherit;
        text-decoration: inherit;

        &:hover,
        &:active,
        &:focus {
            color: inherit;
            text-decoration: inherit;
        }
    }

    p {
        margin: 0;
    }

    .VenstreMenyItemMain {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        height: 50px;
        transition: 0.2s background-color ease;
        min-width: 60px;

        .menyitem-left {
            display: flex;
            flex-direction: row;
            align-items: center;
            min-width: 60px;

            div {
                display: flex;
                justify-content: center;
                width: 60px;
                min-width: 60px;
            }

            p {
                overflow: hidden;
                min-width: 0px;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .menyitem-caret {
            text-align: center;
            width: 50px;
            min-width: 50px;
            overflow: hidden;

            .fa {
                transition: all 0.3s ease;

                #device.desktop({
                    transform: rotate(-90deg);
                }
                );
            }
        }

        #device.desktop({
            &:hover {
                background-color: @darker;
            }
        }
        );
    }

    .VenstreMenyItemUnderMeny {
        overflow: hidden;

        .PaddingWrapper {
            padding: 5px 0;
        }

        .VenstreMenyItemUnderMenyItem {
            cursor: pointer;
            padding-left: 60px;
            height: 30px;
            line-height: 30px;
            align-content: center;
            background-color: transparent;
            transition: 0.2s background-color ease;

            p {
                opacity: 0;
                transition: 0.3s opacity ease;
            }
        }

        .VenstreMenyItemUnderMenyItem:last-child {
            margin-bottom: 5px;
        }
    }

    &.separator {
        margin-bottom: 15px;
    }

    &.undermeny-utvidet {
        #device.mobile({
            background-color: @darker;
        }
        );

        .VenstreMenyItemMain {

            #device.mobile({
                .menyitem-caret .fa {
                    transform: rotate(90deg);
                }
            }
            );
        }

        .VenstreMenyItemUnderMeny {
            opacity: 1;

            .VenstreMenyItemUnderMenyItem {

                #device.desktop({
                    &:hover {
                        background-color: @darker;
                    }
                }
                );

                &.current {
                    box-shadow: @mark;
                }

                p {
                    opacity: 1;
                }
            }
        }
    }

    #device.mobile({
        .VenstreMenyItemUnderMenyDesktop {
            display: none;
        }
    }
    );

    #device.desktop({

        &:hover {
            background-color: @darker;
        }

        .VenstreMenyItemUnderMeny {
            display: none;
        }

        .VenstreMenyItemUnderMenyDesktop {
            opacity: 0;
            display: flex;
            pointer-events: none;
            position: absolute;
            left: 100%;
            top: 0;
            flex-direction: column;
            background-color: @dark;
            transition: 0.1s opacity ease;
            border-left: 1px solid @darker;

            .VenstreMenyItemUnderMenyItem {
                height: 50px;
                display: flex;
                align-items: center;
                padding-left: 15px;
                padding-right: 20px;
                width: 100%;
                font-size: 16px;
                transition: 0.1s background-color ease;

                > p {
                    width: max-content;
                }

                &.current {
                    box-shadow: @mark;
                }

                &:hover {
                    background-color: @darker;
                }
            }
        }

        &:hover {
            .VenstreMenyItemUnderMenyDesktop {
                opacity: 1;
                pointer-events: initial;
            }
        }
    }
    );
}
