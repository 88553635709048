@import '../less/sizes.less';

.ToppMenyItemLinkWrapper {
    color: inherit;
    text-decoration: inherit;

    &:hover, &:active, &:focus {
        color: inherit;
        text-decoration: inherit;
    }

    .ToppMenyItem {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        text-align: center;
        font-size: 24px;
        padding: 0 10px;
        cursor: pointer;
        .tekst{
            user-select: none;
            font-size: 16px;
            line-height: 20px;
            margin-left: 10px;
            margin-right: 10px;
            -webkit-font-smoothing: antialiased;
        }
    }
}
