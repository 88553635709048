@import '../less/sizes.less';
@import '../less/mixins.less';
@import '../less/colors.less';

@li-padding: 5px;

.SoekTreff {
    position: absolute;
    top: 0;
    opacity: 0;
    white-space: nowrap;
    left: @toppmeny-icon-width;
    transition: 0.2s all ease;
    transition-delay: 0.3s;
    overflow: hidden;
    pointer-events: none;

    &:not(.soker) {
        transition-delay: 0s;

        .TreffListe {
            overflow: hidden;
        }
    }

    z-index: -1;

    box-shadow: 0px 1.5px 3px rgba(0, 0, 0, 0.3);

    * {
        user-select: none;
    }

    #device.small( {
            width: initial;
            left: 0;
            right: 0;
        }

    );

    #device.large( {
            min-width: @venstremeny-width;
            border-radius: 0 0 5px 5px;
            max-width: @venstremeny-width * 1.33;
        }

    );

    .sokestatus {
        height: calc(var(--toppmeny-current-height) * 0.618);
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: @grey-base;
        padding: @li-padding 2 * @li-padding;
        color: #fafaf6;
    }

    .TreffListe {
        z-index: -4;
        background-color: #fafaf6;
        padding: 0;
        margin: 0;
        overflow-y: auto;
        box-shadow: 0px 11.5px 3px -10px rgba(0, 0, 0, 0.15) inset, 0px -11.5px 3px -10px rgba(0, 0, 0, 0.15) inset;
        transition: 0.2s max-height ease;

        &::-webkit-scrollbar {
            width: 0;
            background: transparent;
        }

        #device.large( {
                max-height: 0;
            }

        );

        #device.small( {
                max-height: calc(~'100vh' - (var(--toppmeny-mobile-height, @toppmeny-mobile-height) + (var(--toppmeny-mobile-height, @toppmeny-mobile-height) * 0.618)));
            }

        );

        a {
            display: block;
            color: inherit;
            text-decoration: inherit;
        }

        a li {
            border-top: 1px solid mix(black, #fafaf6, 15%);
        }

        a:first-child li {
            border: none;
        }

        li {
            -webkit-font-smoothing: antialiased;
            max-width: 100%;
            display: flex;
            cursor: pointer;
            flex-direction: row;
            align-items: center;
            list-style: none;
            padding: @li-padding 2 * @li-padding;
            overflow: hidden;
            height: calc(var(--toppmeny-height, @toppmeny-height) * 0.618);
            text-overflow: ellipsis;
            background-color: transparent;
            transition: 0.2s background-color ease;

            #device.desktop( {
                    &:hover {
                        background-color: fade(black, 10%);
                    }
                }

            );

            #device.mobile( {
                    height:var(--toppmeny-mobile-height);
                }

            );

            span.navn {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            strong {
                font-weight: normal;
                background-color: fade(black, 10%);
                border-bottom: 1px solid fade(black, 15%);
            }
        }
    }

    &.soker {
        top: var(--toppmeny-current-height);
        opacity: 1;
        pointer-events: all;

        .TreffListe {
            #device.large( {
                    max-height: 310px;
                }

            );
        }
    }

    .begrep {
        display: inline-block;
        min-width: 70px;
        text-align: center;
        font-size: 12px;
        padding: 2px 0;
        color: #fafaf6;
        border-radius: 5px 0 0 5px;
    }

    .treff-pill_employee .begrep {
        background-color: #c6333f;
    }

    .treff-pill_customer .begrep {
        background-color: #31ac84;
    }

    .treff-pill_activity .begrep,
    .treff-pill_project .begrep {
        background-color: #40b2be;
    }

    .id {
        background-color: fade(black, 10%);
        text-align: center;
        padding: 2px;
        font-size: 12px;
        margin-right: 10px;
        min-width: 70px * 0.618;
        border-radius: 0 5px 5px 0;
    }

    #device.small( {
            .begrep {
                padding: 5px 0;
            }

            .id {
                padding: 5px;
            }
        }

    );
}
