@mobile: ~"only screen and (max-width: 750px)";

.MarketplaceElementWrapper {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    border-radius: 4px;
    padding: 15px;
    box-shadow:  0 4px 8px 0 rgba(0,0,0,.06),0 0 0 1px rgba(0,0,0,.02);

    .ElementIcon {
        margin-right: 15px;
    }

    .ContentWrapper {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        align-items: start;

        .ElementName {
            font-weight: bold;
        }

        .ElementDescription {
            font-size: 12px;

            @media @mobile {
                font-size: 11px;
            }
        }

        p {
            margin-bottom: 0;
        }

        @media @mobile {
            align-items: center;
            font-size: 13px;
        }
    }
}

.MarketplaceElementWrapper:hover {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.18),0 0 0 1px rgba(0,0,0,.04);
    cursor: pointer;
}

.MarketplaceElementWrapper:not(:last-child) {
    margin-bottom: 10px;
}
