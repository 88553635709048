@import "../less/colors.less";

@mobile: ~"only screen and (max-width: 750px)";

.test-db-er-mal{
    background-color: @red-light;
    border: 2.5px solid @red-primary;
}
.test-db-er-test{
    background-color: @yellow-light;
    border: 2.5px solid @yellow-primary;
}

.test-db-panel-wrapper {
    border-radius: 5px;

    padding: 5px;

    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;

    @media @mobile {
        flex-direction: column;
        justify-content: flex-start;
    }

    .icon-text-wrapper {
        display: flex;
        margin-left: 10px;
        font-size: 16px;
        align-items: center;

        .test-db-wrench-icon {
            font-size: 1.5em;
            margin-left: 15px;
            margin-right: 15px;
        }

        .test-db-txt {
            font-weight: bold;
            margin-left: 5px;
            margin-right: 5px;
        }
    }
}

