@import '../less/sizes.less';
@import '../less/mixins.less';
@import '../less/colors.less';

.TWrapper {
    height: 0;

    .ToppMeny {
        position: fixed;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        z-index: 1000;
        top: 0;
        left: 0;
        right: 0;
        background-color: #f0f2f2;
        color: @grey-base;
        height: var(--toppmeny-current-height);
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);

        .ToppMenyWrapper {
            padding: 0 10px;
            height: 100%;
            width: 100%;
            background-color: #f0f2f2;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .fa {
            font-size: 20px;
        }

        #device.mobile({
            height: var(--toppmeny-current-height);
        }
        );

        #device.small({
            transition: margin 0.3s ease;

            &:not(.soker) {
                transition-delay: 0.2s;
            }

            &.soker {
                margin-left: -55px;
                margin-right: -155px;
            }

        }
        );

        a.logo {
            height: calc(var(--toppmeny-height, @toppmeny-height) / 2);
            color: inherit;
            text-decoration: inherit;
        }

        svg.logo {
            display: initial;
            height: calc(var(--toppmeny-height, @toppmeny-height) / 2);
            padding-left: 5px;
            cursor: pointer;

            #device.mobile({
                display: none;
            });
        }

        .ToppMenyRight {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background-color: #f0f2f2;
            height: 100%;

            #device.mobile({
                flex: 1;
            });

            .ToppMenyRightLeft {
                display: flex;
                flex-direction: row;
                height: 100%;

                #device.mobile({
                    flex: 1;
                });

                #device.large({
                    justify-content: space-between;
                });
            }

            .ToppMenyRightRight {
                display: flex;
                flex-direction: row;
                height: 100%;

                .ToppMenyRightRightMeldinger {
                    place-items: center;
                    display: flex;
                }

                .ToppMenyRightRightHjelp {
                    #device.small({
                        display: none;
                    }
                    );
                }
            }

            .Varslinger {
                display: flex;
                place-items: center;
                padding: 0 10px;
            }
        }
    }
}
