.KunngjoringsMelding {
  width: fit-content;
  max-width: 99%;
  margin: 1rem auto auto;
}

.KunngjoringsMelding .notification .right {
  grid-template-columns: min-content min-content;
}

.KunngjoringsMelding .notification .right .close {
  opacity: 1;
  grid-column: 2;
}

.KunngjoringsMelding .notification .right .close:hover {
  opacity: .7;
}

.tb-button[data-icon="true"] {
  width: 36.5px;
  min-width: 36.5px;
  height: 36.5px;
  padding: 0;
}

.tb-button[data-icon="true"][data-circular="true"] {
  border-radius: 50%;
}

.tb-button[data-icon="true"][data-size="small"] {
  width: 30.75px;
  min-width: 30.75px;
  height: 30.75px;
  font-size: .75rem;
}

.tb-button[data-icon="true"][data-size="large"] {
  width: 42.25px;
  min-width: 42.25px;
  height: 42.25px;
  font-size: 1.25rem;
}

.tb-button[data-loading="true"] .MuiButton-endIcon, .tb-button[data-loading="true"] .MuiButton-startIcon, .tb-button[data-loading="true"] .tb-button-children {
  opacity: 0;
}

.HelpArticle {
  background-color: #fff;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 20px;
  position: absolute;
}

.HelpDocument {
  background-color: #fff;
}

.HelpDocument .HelpExternalLink i {
  margin-left: 5px;
}

.HelpDocument .HelpContent {
  display: flex;
}

.HelpDocument .HelpContent .HelpMainContent {
  flex: auto;
  padding: 20px;
}

.HelpDocument .HelpContent .HelpMainContent img, .HelpDocument .HelpContent .HelpMainContent iframe {
  max-width: 100%;
}

.HelpDocument .HelpContent .HelpSideContent {
  background-color: #eee;
  border-radius: 5px;
  height: 100%;
  padding: 10px;
}

@media screen and (max-width: 767px) {
  .HelpDocument .HelpContent {
    flex-direction: column;
  }
}

html.touch .HelpDocument .HelpContent {
  flex-direction: column;
}

html .ModalWrapper .IdleTimerModal .ButtonWrapper .ActionAndCloseWrapper {
  justify-content: space-evenly;
}

html .ModalWrapper .IdleTimerModal .ButtonWrapper .ActionAndCloseWrapper .ActionButtonModal {
  width: 45%;
}

html .ModalWrapper .IdleTimerModal .timer-remaining {
  font-weight: bold;
}

.ToppMenyItemLinkWrapper, .ToppMenyItemLinkWrapper:hover, .ToppMenyItemLinkWrapper:active, .ToppMenyItemLinkWrapper:focus {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

.ToppMenyItemLinkWrapper .ToppMenyItem {
  text-align: center;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 10px;
  font-size: 24px;
  display: flex;
}

.ToppMenyItemLinkWrapper .ToppMenyItem .tekst {
  -webkit-font-smoothing: antialiased;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 16px;
  line-height: 20px;
}

.PulldownWrapperNotifications {
  right: 0;
  top: var(--toppmeny-current-height);
  opacity: 1;
  border-radius: 0 0 5px 5px;
  flex-direction: column;
  width: 494px;
  font-size: 16px;
  transition: top .2s, opacity .2s;
  display: flex;
  position: fixed;
  overflow: hidden;
  box-shadow: 0 1.5px 3px rgba(0, 0, 0, .3);
}

@media screen and (max-width: 767px) {
  .PulldownWrapperNotifications {
    border-radius: 0;
    width: 100vw;
  }
}

.PulldownWrapperNotifications .PulldownInnhold {
  -webkit-user-select: none;
  user-select: none;
  background-color: #fafaf6;
}

@media screen and (min-width: 768px) {
  .PulldownWrapperNotifications .PulldownInnhold {
    height: 100%;
  }
}

.PulldownWrapperNotifications .PulldownInnhold .notification-heading {
  background-color: #29343d;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 37px;
  display: flex;
}

.PulldownWrapperNotifications .PulldownInnhold .notification-heading .notification-heading-text {
  color: #fff;
  margin: 0 10px 0 0;
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
}

.PulldownWrapperNotifications .PulldownInnhold .notification-heading .notification-heading-button {
  color: #fff;
  margin: 0;
}

.PulldownWrapperNotifications .PulldownInnhold .toggle-buttons-wrapper {
  justify-content: space-around;
  display: flex;
}

.PulldownWrapperNotifications .PulldownInnhold .toggle-buttons-wrapper .toggle-button {
  color: #2d3a44;
  cursor: pointer;
  background-color: #fafaf6;
  border: 1px solid #b9b9b9;
  border-left: none;
  border-right: none;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 25px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
}

.PulldownWrapperNotifications .PulldownInnhold .toggle-buttons-wrapper .toggle-button.active {
  background-color: #dcdcdc;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, .1);
}

.PulldownWrapperNotifications .PulldownInnhold .notification-wrapper, .PulldownWrapperNotifications .PulldownInnhold .notification-wrapper .empty-notification-list {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.PulldownWrapperNotifications .PulldownKnapper {
  -webkit-user-select: none;
  user-select: none;
  height: calc(var(--toppmeny-current-height) * .618);
  background-color: #2d3a44;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  display: flex;
}

.PulldownWrapperNotifications .PulldownKnapper .PulldownKnapp {
  color: #fafaf6;
  cursor: pointer;
}

.PulldownWrapperNotifications.skjult {
  pointer-events: none;
  opacity: 0;
  top: 0;
}

@media screen and (min-width: 768px) {
  .PulldownWrapperNotifications.skjult .PulldownInnhold {
    height: 0;
  }
}

.MarketplaceElementWrapper {
  border-radius: 4px;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 15px;
  display: flex;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .06), 0 0 0 1px rgba(0, 0, 0, .02);
}

.MarketplaceElementWrapper .ElementIcon {
  margin-right: 15px;
}

.MarketplaceElementWrapper .ContentWrapper {
  flex-direction: column;
  align-items: start;
  font-size: 16px;
  display: flex;
}

.MarketplaceElementWrapper .ContentWrapper .ElementName {
  font-weight: bold;
}

.MarketplaceElementWrapper .ContentWrapper .ElementDescription {
  font-size: 12px;
}

@media only screen and (max-width: 750px) {
  .MarketplaceElementWrapper .ContentWrapper .ElementDescription {
    font-size: 11px;
  }
}

.MarketplaceElementWrapper .ContentWrapper p {
  margin-bottom: 0;
}

@media only screen and (max-width: 750px) {
  .MarketplaceElementWrapper .ContentWrapper {
    align-items: center;
    font-size: 13px;
  }
}

.MarketplaceElementWrapper:hover {
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .18), 0 0 0 1px rgba(0, 0, 0, .04);
}

.MarketplaceElementWrapper:not(:last-child) {
  margin-bottom: 10px;
}

.SoekTreff {
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  z-index: -1;
  transition: all .2s .3s;
  position: absolute;
  top: 0;
  left: 45px;
  overflow: hidden;
  box-shadow: 0 1.5px 3px rgba(0, 0, 0, .3);
}

.SoekTreff:not(.soker) {
  transition-delay: 0s;
}

.SoekTreff:not(.soker) .TreffListe {
  overflow: hidden;
}

.SoekTreff * {
  -webkit-user-select: none;
  user-select: none;
}

@media screen and (max-width: 767px) {
  .SoekTreff {
    width: initial;
    left: 0;
    right: 0;
  }
}

@media screen and (min-width: 768px) {
  .SoekTreff {
    border-radius: 0 0 5px 5px;
    min-width: 260px;
    max-width: 345.8px;
  }
}

.SoekTreff .sokestatus {
  height: calc(var(--toppmeny-current-height) * .618);
  color: #fafaf6;
  background-color: #29343d;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  display: flex;
}

.SoekTreff .TreffListe {
  z-index: -4;
  background-color: #fafaf6;
  margin: 0;
  padding: 0;
  transition: max-height .2s;
  overflow-y: auto;
  box-shadow: inset 0 11.5px 3px -10px rgba(0, 0, 0, .15), inset 0 -11.5px 3px -10px rgba(0, 0, 0, .15);
}

.SoekTreff .TreffListe::-webkit-scrollbar {
  background: none;
  width: 0;
}

@media screen and (min-width: 768px) {
  .SoekTreff .TreffListe {
    max-height: 0;
  }
}

@media screen and (max-width: 767px) {
  .SoekTreff .TreffListe {
    max-height: calc(100vh - (var(--toppmeny-mobile-height, 50px)  + (var(--toppmeny-mobile-height, 50px) * .618)));
  }
}

.SoekTreff .TreffListe a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  display: block;
}

.SoekTreff .TreffListe a li {
  border-top: 1px solid #d5d5d1;
}

.SoekTreff .TreffListe a:first-child li {
  border: none;
}

.SoekTreff .TreffListe li {
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  height: calc(var(--toppmeny-height, 60px) * .618);
  text-overflow: ellipsis;
  background-color: rgba(0, 0, 0, 0);
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  padding: 5px 10px;
  list-style: none;
  transition: background-color .2s;
  display: flex;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  html.no-touch .SoekTreff .TreffListe li:hover {
    background-color: rgba(0, 0, 0, .1);
  }
}

@media screen and (max-width: 767px) {
  .SoekTreff .TreffListe li {
    height: var(--toppmeny-mobile-height);
  }
}

html.touch .SoekTreff .TreffListe li {
  height: var(--toppmeny-mobile-height);
}

.SoekTreff .TreffListe li span.navn {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.SoekTreff .TreffListe li strong {
  background-color: rgba(0, 0, 0, .1);
  border-bottom: 1px solid rgba(0, 0, 0, .15);
  font-weight: normal;
}

.SoekTreff.soker {
  top: var(--toppmeny-current-height);
  opacity: 1;
  pointer-events: all;
}

@media screen and (min-width: 768px) {
  .SoekTreff.soker .TreffListe {
    max-height: 310px;
  }
}

.SoekTreff .begrep {
  text-align: center;
  color: #fafaf6;
  border-radius: 5px 0 0 5px;
  min-width: 70px;
  padding: 2px 0;
  font-size: 12px;
  display: inline-block;
}

.SoekTreff .treff-pill_employee .begrep {
  background-color: #c6333f;
}

.SoekTreff .treff-pill_customer .begrep {
  background-color: #31ac84;
}

.SoekTreff .treff-pill_activity .begrep, .SoekTreff .treff-pill_project .begrep {
  background-color: #40b2be;
}

.SoekTreff .id {
  text-align: center;
  background-color: rgba(0, 0, 0, .1);
  border-radius: 0 5px 5px 0;
  min-width: 43.26px;
  margin-right: 10px;
  padding: 2px;
  font-size: 12px;
}

@media screen and (max-width: 767px) {
  .SoekTreff .begrep {
    padding: 5px 0;
  }

  .SoekTreff .id {
    padding: 5px;
  }
}

.SearchWrapper {
  min-height: var(--toppmeny-current-height);
  flex-direction: column;
  font-size: 16px;
  display: flex;
  position: relative;
}

@media screen and (max-width: 767px) {
  .SearchWrapper {
    flex: 1;
    padding-right: 10px;
  }
}

.Search {
  text-align: center;
  flex-direction: row;
  flex: 1;
  align-items: center;
  font-size: 24px;
  display: flex;
}

.Search > a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  height: 100%;
}

.Search .SearchIcon {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 100%;
  display: flex;
}

.Search input {
  opacity: 0;
  height: calc(var(--toppmeny-mobile-height, 50px)  - 20);
  background: none;
  border: none;
  border-bottom: 2px solid #29343d;
  border-radius: 0;
  width: 0;
  margin: 0;
  padding: 0;
  font-size: 16px;
  transition: all .3s;
}

.Search input:focus {
  outline: none;
}

.Search:not(.soker) input {
  transition-delay: .2s;
}

@media screen and (max-width: 767px) {
  .Search input {
    flex: 1;
    min-width: 0;
  }
}

.Search.soker input {
  opacity: 1;
  border-bottom: 2px solid #29343d;
}

@media screen and (min-width: 768px) {
  .Search.soker {
    flex-direction: row;
    align-items: center;
    display: flex;
  }

  .Search.soker input {
    width: 260px;
    padding: 0 10px;
  }
}

.TBLogo .cls-1 {
  fill: #29343d;
}

.TBLogo .cls-2 {
  fill: #cb333b;
}

.test-db-er-mal {
  background-color: #faebeb;
  border: 2.5px solid #cd333b;
}

.test-db-er-test {
  background-color: #fff3e6;
  border: 2.5px solid #ffaa4d;
}

.test-db-panel-wrapper {
  border-radius: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  display: flex;
}

@media only screen and (max-width: 750px) {
  .test-db-panel-wrapper {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.test-db-panel-wrapper .icon-text-wrapper {
  align-items: center;
  margin-left: 10px;
  font-size: 16px;
  display: flex;
}

.test-db-panel-wrapper .icon-text-wrapper .test-db-wrench-icon {
  margin-left: 15px;
  margin-right: 15px;
  font-size: 1.5em;
}

.test-db-panel-wrapper .icon-text-wrapper .test-db-txt {
  margin-left: 5px;
  margin-right: 5px;
  font-weight: bold;
}

.ToppMenyBruker {
  place-items: center;
  display: flex;
  position: relative;
}

.ToppMenyBruker > a, .ToppMenyBruker > a:hover, .ToppMenyBruker > a:active, .ToppMenyBruker > a:focus {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

.ToppMenyBruker .avatar {
  cursor: pointer;
  width: 3.125rem;
  height: 3.125rem;
}

@media only screen and (max-width: 768px) {
  .ToppMenyBruker .avatar {
    width: 2.75rem;
    height: 2.75rem;
  }
}

.ToppMenyBruker .BrukerBilde img, .ToppMenyBruker .BrukerBilde div {
  border-radius: 999px;
  width: 100%;
  height: 100%;
  display: block;
}

.ToppMenyBruker .BrukerBilde div {
  border: 1px solid #d3d3d3;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ToppMenyBruker .BrukerBilde div p {
  color: #fff;
  margin: 0;
}

.ToppMenyBruker .brukerKnapp {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  height: var(--toppmeny-current-height);
}

.ToppMenyBruker .brukerKnapp .BrukerBilde {
  height: var(--toppmeny-current-height);
  width: var(--toppmeny-current-height);
  padding: 7px;
}

.ToppMenyBruker .PulldownWrapper {
  z-index: -1;
  right: 0;
  top: var(--toppmeny-current-height);
  opacity: 1;
  border-radius: 0 0 5px 5px;
  flex-direction: column;
  width: 420.68px;
  font-size: 16px;
  transition: top .2s, opacity .2s;
  display: flex;
  position: absolute;
  overflow: hidden;
  box-shadow: 0 1.5px 3px rgba(0, 0, 0, .3);
}

@media screen and (max-width: 767px) {
  .ToppMenyBruker .PulldownWrapper {
    border-radius: 0;
    width: 100vw;
    right: -10px;
  }
}

.ToppMenyBruker .PulldownWrapper .PulldownInnhold {
  background-color: #fafaf6;
  flex-direction: row;
  align-items: center;
  transition: height .2s;
  display: flex;
  position: relative;
  box-shadow: inset 0 11.5px 3px -10px rgba(0, 0, 0, .15), inset 0 -11.5px 3px -10px rgba(0, 0, 0, .15);
}

@media screen and (min-width: 768px) {
  .ToppMenyBruker .PulldownWrapper .PulldownInnhold {
    height: calc(2 * var(--toppmeny-height, 60px)  + 4 * 10px);
  }
}

.ToppMenyBruker .PulldownWrapper .PulldownInnhold .InnholdVenstre {
  align-items: center;
  height: 100%;
  padding: 20px 10px;
  display: flex;
}

.ToppMenyBruker .PulldownWrapper .PulldownInnhold .InnholdVenstre .BrukerBilde {
  width: calc(2 * var(--toppmeny-height, 60px));
  height: calc(2 * var(--toppmeny-height, 60px));
}

.ToppMenyBruker .PulldownWrapper .PulldownInnhold .InnholdVenstre .BrukerBilde p {
  font-size: 40px;
}

.ToppMenyBruker .PulldownWrapper .PulldownInnhold .InnholdHoyre {
  flex-direction: column;
  display: flex;
}

.ToppMenyBruker .PulldownWrapper .PulldownInnhold .InnholdHoyre .brukerAkt {
  border-radius: 5px;
  font-size: 14px;
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
}

.ToppMenyBruker .PulldownWrapper .PulldownInnhold .InnholdHoyre .brukerAkt span {
  padding: 4px 10px;
  display: inline-block;
}

.ToppMenyBruker .PulldownWrapper .PulldownInnhold .InnholdHoyre .brukerAkt .aktType {
  color: #fafaf6;
  background-color: #40b2be;
}

.ToppMenyBruker .PulldownWrapper .PulldownInnhold .InnholdHoyre .brukerAkt .aktNavn {
  color: #29343d;
  background-color: rgba(0, 0, 0, .1);
}

.ToppMenyBruker .PulldownWrapper .PulldownInnhold .InnholdHoyre .brukerAkt:hover .aktType {
  color: #fafaf6;
  background-color: #3aa0ab;
}

.ToppMenyBruker .PulldownWrapper .PulldownInnhold .InnholdHoyre .brukerAkt:hover .aktNavn {
  color: #29343d;
  background-color: rgba(0, 0, 0, .19);
}

.ToppMenyBruker .PulldownWrapper .PulldownKnapper {
  height: calc(var(--toppmeny-current-height) * .618);
  background-color: #29343d;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  display: flex;
}

.ToppMenyBruker .PulldownWrapper .PulldownKnapper a {
  color: #fafaf6;
  cursor: pointer;
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .ToppMenyBruker .PulldownWrapper .PulldownKnapper a {
    text-decoration: underline;
  }
}

.ToppMenyBruker .PulldownWrapper.skjult {
  pointer-events: none;
  opacity: 0;
  top: 0;
}

@media screen and (min-width: 768px) {
  .ToppMenyBruker .PulldownWrapper.skjult .PulldownInnhold {
    height: 0;
  }
}

.ToppMenyItemLinkWrapper, .ToppMenyItemLinkWrapper:hover, .ToppMenyItemLinkWrapper:active, .ToppMenyItemLinkWrapper:focus {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

.ToppMenyItemLinkWrapper .ToppMenyItem {
  text-align: center;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 10px;
  font-size: 24px;
  display: flex;
}

.ToppMenyItemLinkWrapper .ToppMenyItem .tekst {
  -webkit-user-select: none;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 16px;
  line-height: 20px;
}

.VisMenyKnapp {
  cursor: pointer;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 45px;
  height: 100%;
  display: flex;
}

@media screen and (min-width: 768px) {
  html.no-touch .VisMenyKnapp {
    display: none;
  }
}

.VisMenyKnapp .KnappBar {
  background-color: #29343d;
  border-radius: 1px;
  width: 22px;
  height: 2px;
  margin: 6px 0;
  transition: transform .4s;
  display: block;
}

.VisMenyKnapp .KnappBar.rotate-kb-1 {
  transform: translateY(4px)rotate(135deg);
}

.VisMenyKnapp .KnappBar.rotate-kb-2 {
  transform: translateY(-4px)rotate(45deg);
}

.TWrapper {
  height: 0;
}

.TWrapper .ToppMeny {
  z-index: 1000;
  color: #29343d;
  height: var(--toppmeny-current-height);
  background-color: #f0f2f2;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .15);
}

.TWrapper .ToppMeny .ToppMenyWrapper {
  background-color: #f0f2f2;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  display: flex;
}

.TWrapper .ToppMeny .fa {
  font-size: 20px;
}

@media screen and (max-width: 767px) {
  .TWrapper .ToppMeny {
    height: var(--toppmeny-current-height);
  }
}

html.touch .TWrapper .ToppMeny {
  height: var(--toppmeny-current-height);
}

@media screen and (max-width: 767px) {
  .TWrapper .ToppMeny {
    transition: margin .3s;
  }

  .TWrapper .ToppMeny:not(.soker) {
    transition-delay: .2s;
  }

  .TWrapper .ToppMeny.soker {
    margin-left: -55px;
    margin-right: -155px;
  }
}

.TWrapper .ToppMeny a.logo {
  height: calc(var(--toppmeny-height, 60px) / 2);
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

.TWrapper .ToppMeny svg.logo {
  display: initial;
  height: calc(var(--toppmeny-height, 60px) / 2);
  cursor: pointer;
  padding-left: 5px;
}

@media screen and (max-width: 767px) {
  .TWrapper .ToppMeny svg.logo {
    display: none;
  }
}

html.touch .TWrapper .ToppMeny svg.logo {
  display: none;
}

.TWrapper .ToppMeny .ToppMenyRight {
  background-color: #f0f2f2;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  display: flex;
}

@media screen and (max-width: 767px) {
  .TWrapper .ToppMeny .ToppMenyRight {
    flex: 1;
  }
}

html.touch .TWrapper .ToppMeny .ToppMenyRight {
  flex: 1;
}

.TWrapper .ToppMeny .ToppMenyRight .ToppMenyRightLeft {
  flex-direction: row;
  height: 100%;
  display: flex;
}

@media screen and (max-width: 767px) {
  .TWrapper .ToppMeny .ToppMenyRight .ToppMenyRightLeft {
    flex: 1;
  }
}

html.touch .TWrapper .ToppMeny .ToppMenyRight .ToppMenyRightLeft {
  flex: 1;
}

@media screen and (min-width: 768px) {
  .TWrapper .ToppMeny .ToppMenyRight .ToppMenyRightLeft {
    justify-content: space-between;
  }
}

.TWrapper .ToppMeny .ToppMenyRight .ToppMenyRightRight {
  flex-direction: row;
  height: 100%;
  display: flex;
}

.TWrapper .ToppMeny .ToppMenyRight .ToppMenyRightRight .ToppMenyRightRightMeldinger {
  place-items: center;
  display: flex;
}

@media screen and (max-width: 767px) {
  .TWrapper .ToppMeny .ToppMenyRight .ToppMenyRightRight .ToppMenyRightRightHjelp {
    display: none;
  }
}

.TWrapper .ToppMeny .ToppMenyRight .Varslinger {
  place-items: center;
  padding: 0 10px;
  display: flex;
}

.VenstreMenyItem {
  -webkit-user-select: none;
  user-select: none;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  transition: background-color .3s;
  position: relative;
}

.VenstreMenyItem.current .VenstreMenyItemMain {
  box-shadow: inset 5px 0 #3878c7;
}

.VenstreMenyItem a, .VenstreMenyItem a:hover, .VenstreMenyItem a:active, .VenstreMenyItem a:focus {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

.VenstreMenyItem p {
  margin: 0;
}

.VenstreMenyItem .VenstreMenyItemMain {
  cursor: pointer;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 60px;
  height: 50px;
  font-size: 16px;
  transition: background-color .2s;
  display: flex;
}

.VenstreMenyItem .VenstreMenyItemMain .menyitem-left {
  flex-direction: row;
  align-items: center;
  min-width: 60px;
  display: flex;
}

.VenstreMenyItem .VenstreMenyItemMain .menyitem-left div {
  justify-content: center;
  width: 60px;
  min-width: 60px;
  display: flex;
}

.VenstreMenyItem .VenstreMenyItemMain .menyitem-left p {
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
  overflow: hidden;
}

.VenstreMenyItem .VenstreMenyItemMain .menyitem-caret {
  text-align: center;
  width: 50px;
  min-width: 50px;
  overflow: hidden;
}

.VenstreMenyItem .VenstreMenyItemMain .menyitem-caret .fa {
  transition: all .3s;
}

@media screen and (min-width: 768px) {
  html.no-touch .VenstreMenyItem .VenstreMenyItemMain .menyitem-caret .fa {
    transform: rotate(-90deg);
  }

  html.no-touch .VenstreMenyItem .VenstreMenyItemMain:hover {
    background-color: #151e26;
  }
}

.VenstreMenyItem .VenstreMenyItemUnderMeny {
  overflow: hidden;
}

.VenstreMenyItem .VenstreMenyItemUnderMeny .PaddingWrapper {
  padding: 5px 0;
}

.VenstreMenyItem .VenstreMenyItemUnderMeny .VenstreMenyItemUnderMenyItem {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  align-content: center;
  height: 30px;
  padding-left: 60px;
  line-height: 30px;
  transition: background-color .2s;
}

.VenstreMenyItem .VenstreMenyItemUnderMeny .VenstreMenyItemUnderMenyItem p {
  opacity: 0;
  transition: opacity .3s;
}

.VenstreMenyItem .VenstreMenyItemUnderMeny .VenstreMenyItemUnderMenyItem:last-child {
  margin-bottom: 5px;
}

.VenstreMenyItem.separator {
  margin-bottom: 15px;
}

@media screen and (max-width: 767px) {
  .VenstreMenyItem.undermeny-utvidet {
    background-color: #151e26;
  }
}

html.touch .VenstreMenyItem.undermeny-utvidet {
  background-color: #151e26;
}

@media screen and (max-width: 767px) {
  .VenstreMenyItem.undermeny-utvidet .VenstreMenyItemMain .menyitem-caret .fa {
    transform: rotate(90deg);
  }
}

html.touch .VenstreMenyItem.undermeny-utvidet .VenstreMenyItemMain .menyitem-caret .fa {
  transform: rotate(90deg);
}

.VenstreMenyItem.undermeny-utvidet .VenstreMenyItemUnderMeny {
  opacity: 1;
}

@media screen and (min-width: 768px) {
  html.no-touch .VenstreMenyItem.undermeny-utvidet .VenstreMenyItemUnderMeny .VenstreMenyItemUnderMenyItem:hover {
    background-color: #151e26;
  }
}

.VenstreMenyItem.undermeny-utvidet .VenstreMenyItemUnderMeny .VenstreMenyItemUnderMenyItem.current {
  box-shadow: inset 5px 0 #3878c7;
}

.VenstreMenyItem.undermeny-utvidet .VenstreMenyItemUnderMeny .VenstreMenyItemUnderMenyItem p {
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .VenstreMenyItem .VenstreMenyItemUnderMenyDesktop {
    display: none;
  }
}

html.touch .VenstreMenyItem .VenstreMenyItemUnderMenyDesktop {
  display: none;
}

@media screen and (min-width: 768px) {
  html.no-touch .VenstreMenyItem:hover {
    background-color: #151e26;
  }

  html.no-touch .VenstreMenyItem .VenstreMenyItemUnderMeny {
    display: none;
  }

  html.no-touch .VenstreMenyItem .VenstreMenyItemUnderMenyDesktop {
    opacity: 0;
    pointer-events: none;
    background-color: #29343d;
    border-left: 1px solid #151e26;
    flex-direction: column;
    transition: opacity .1s;
    display: flex;
    position: absolute;
    top: 0;
    left: 100%;
  }

  html.no-touch .VenstreMenyItem .VenstreMenyItemUnderMenyDesktop .VenstreMenyItemUnderMenyItem {
    align-items: center;
    width: 100%;
    height: 50px;
    padding-left: 15px;
    padding-right: 20px;
    font-size: 16px;
    transition: background-color .1s;
    display: flex;
  }

  html.no-touch .VenstreMenyItem .VenstreMenyItemUnderMenyDesktop .VenstreMenyItemUnderMenyItem > p {
    width: max-content;
  }

  html.no-touch .VenstreMenyItem .VenstreMenyItemUnderMenyDesktop .VenstreMenyItemUnderMenyItem.current {
    box-shadow: inset 5px 0 #3878c7;
  }

  html.no-touch .VenstreMenyItem .VenstreMenyItemUnderMenyDesktop .VenstreMenyItemUnderMenyItem:hover {
    background-color: #151e26;
  }

  html.no-touch .VenstreMenyItem:hover .VenstreMenyItemUnderMenyDesktop {
    opacity: 1;
    pointer-events: initial;
  }
}

.VenstreMenyContainer {
  padding-top: var(--toppmeny-height);
  z-index: 100;
  flex-direction: row;
  height: 100%;
  display: flex;
  position: fixed;
}

.VenstreMenyContainer .VenstreMeny, .VenstreMenyContainer .VenstreMenyOverlay {
  transition-property: margin-left, opacity;
  transition-duration: .4s;
  transition-timing-function: ease;
}

.VenstreMenyContainer .VenstreMeny {
  color: #f0f2f2;
  -webkit-overflow-scrolling: touch;
  background-color: #29343d;
  width: 260px;
  padding: 35px 0 15px;
  position: relative;
}

@media screen and (max-width: 767px) {
  .VenstreMenyContainer .VenstreMeny {
    overflow-y: auto;
  }
}

html.touch .VenstreMenyContainer .VenstreMeny {
  overflow-y: auto;
}

@media screen and (min-width: 768px) {
  html.no-touch .VenstreMenyContainer .VenstreMeny {
    width: 60px;
    transition: width .3s ease-in-out;
    overflow-x: visible;
  }

  html.no-touch .VenstreMenyContainer .VenstreMeny .menyitem-left > p, html.no-touch .VenstreMenyContainer .VenstreMeny .menyitem-caret {
    opacity: 0;
    transition-property: opacity;
    transition-duration: .2s;
    transition-delay: .1s;
  }

  html.no-touch .VenstreMenyContainer .VenstreMeny:hover {
    width: 260px;
  }

  html.no-touch .VenstreMenyContainer .VenstreMeny:hover .menyitem-left > p, html.no-touch .VenstreMenyContainer .VenstreMeny:hover .menyitem-caret, html.no-touch .VenstreMenyContainer .VenstreMeny:hover .Pin {
    opacity: 1;
  }

  html.no-touch .VenstreMenyContainer .VenstreMeny:not(:hover), html.no-touch .VenstreMenyContainer .VenstreMeny:not(:hover) .menyitem-left > p, html.no-touch .VenstreMenyContainer .VenstreMeny:not(:hover) .menyitem-caret, html.no-touch .VenstreMenyContainer .VenstreMeny:not(:hover) .Pin {
    transition-delay: .4s;
  }

  html.no-touch .VenstreMenyContainer .VenstreMeny:not(:hover) {
    overflow-x: hidden;
  }

  html.no-touch .VenstreMenyContainer .VenstreMeny .HjelpItem {
    display: none;
  }

  html.no-touch.menu-pinned .VenstreMenyContainer .VenstreMeny {
    width: 260px;
  }

  html.no-touch.menu-pinned .VenstreMenyContainer .VenstreMeny .menyitem-left > p, html.no-touch.menu-pinned .VenstreMenyContainer .VenstreMeny .menyitem-caret, html.no-touch.menu-pinned .VenstreMenyContainer .VenstreMeny .Pin {
    opacity: 1;
  }
}

.VenstreMenyContainer .VenstreMeny::-webkit-scrollbar {
  background: none;
  width: 0;
}

.VenstreMenyContainer .VenstreMenyOverlay {
  opacity: 0;
  background-color: rgba(0, 0, 0, .5);
  flex: 1;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .VenstreMenyContainer {
    padding-top: var(--toppmeny-mobile-height);
    width: 100%;
  }

  .VenstreMenyContainer .VenstreMenyOverlay {
    opacity: 1;
  }

  .VenstreMenyContainer.hide-mobile {
    pointer-events: none;
  }

  .VenstreMenyContainer.hide-mobile .VenstreMeny {
    margin-left: -260px;
  }

  .VenstreMenyContainer.hide-mobile .VenstreMenyOverlay {
    opacity: 0;
  }
}

html.touch .VenstreMenyContainer {
  padding-top: var(--toppmeny-mobile-height);
  width: 100%;
}

html.touch .VenstreMenyContainer .VenstreMenyOverlay {
  opacity: 1;
}

html.touch .VenstreMenyContainer.hide-mobile {
  pointer-events: none;
}

html.touch .VenstreMenyContainer.hide-mobile .VenstreMeny {
  margin-left: -260px;
}

html.touch .VenstreMenyContainer.hide-mobile .VenstreMenyOverlay {
  opacity: 0;
}

.VenstreMenyContainer .Pin {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  opacity: 0;
  border-radius: 25px / 2px;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  transition: opacity .3s, background-color .1s;
  display: flex;
  position: absolute;
  top: 5px;
  right: 12.5px;
}

@media screen and (max-width: 767px) {
  .VenstreMenyContainer .Pin {
    display: none;
  }
}

html.touch .VenstreMenyContainer .Pin {
  display: none;
}

.VenstreMenyContainer .Pin:hover {
  background-color: rgba(0, 0, 0, .25);
}

.VenstreMenyContainer .Pin:hover.active {
  background-color: rgba(0, 0, 0, .5);
}

.VenstreMenyContainer .Pin.active {
  background-color: rgba(0, 0, 0, .25);
}

.VenstreMenyContainer .Pin:active .fa {
  transform: translateY(-1px);
}

:root {
  --toppmeny-height: 60px;
  --toppmeny-mobile-height: 50px;
  --toppmeny-current-height: var(--toppmeny-height, 60px);
}

@media screen and (max-width: 767px) {
  :root {
    --toppmeny-current-height: var(--toppmeny-mobile-height, 50px);
  }
}

html.touch :root {
  --toppmeny-current-height: var(--toppmeny-mobile-height, 50px);
}

html, body {
  height: 100%;
}

html {
  font-size: 16px !important;
}

body {
  -webkit-font-smoothing: antialiased;
  color: #29343d;
  font-family: Open Sans, sans-serif;
}

#navmeny {
  z-index: 50;
}

@media print {
  #navmeny {
    display: none;
  }
}

#hovedcontainer-wrapper {
  -webkit-overflow-scrolling: touch;
  height: 100%;
}

.menu-pad {
  bottom: 0;
  right: 0;
  top: var(--toppmeny-current-height);
}

@media screen and (max-width: 767px) {
  .menu-pad {
    left: 0;
  }
}

html.touch .menu-pad {
  left: 0;
}

@media screen and (min-width: 768px) {
  html.no-touch .menu-pad {
    left: 60px;
  }

  html.no-touch.menu-pinned .menu-pad {
    padding-left: 260px;
  }
}

#hovedcontainer {
  padding-bottom: 100px;
  padding-top: var(--toppmeny-current-height);
}

@media screen and (min-width: 768px) {
  html.no-touch #hovedcontainer {
    padding-left: 60px;
  }

  html.no-touch.menu-pinned #hovedcontainer {
    padding-left: 260px;
  }
}

#hovedcontainer #hovedinnhold {
  padding-top: 20px;
}

#hubspot-messages-iframe-container.widget-align-right.tb-hubspot-container {
  right: 40px !important;
}

@media print {
  #hubspot-messages-iframe-container.widget-align-right.tb-hubspot-container {
    display: none !important;
  }
}

/*# sourceMappingURL=style.css.map */
