// Device classification namespace
#device() {

    // SMALL: Devices < 768px
    .small(@rules) {
        @media screen and (max-width: 767px) {
            @rules();
        }
    }

    // LARGE: Devices >= 768px
    .large(@rules) {
        @media screen and (min-width: 768px) {
            @rules();
        }
    }

    // MOBILE: Devices < 768px, devices w/ touch
    .mobile(@rules) {
        @media screen and (max-width: 767px) {
            @rules();
        }

        html.touch & {
            @rules();
        }
    }

    // DESKTOP: Devices >= 768px w/o touch
    .desktop(@rules) {
        @media screen and (min-width: 768px) {
            html.no-touch & {
                @rules();
            }
        }
    }

    // Same as DESKTOP, but only with pinned menu
    .desktop_menu-pinned(@rules) {
        @media screen and (min-width: 768px) {
            html.no-touch.menu-pinned & {
                @rules();
            }
        }
    }

    ;
}

// Mixin for creating a square
.square(@size) {
    width: @size;
    height: @size;
}

// Alias for .square() mixin
.size(@size) {
    .square(@size);
}

// Mixin for creating a circle
.circle(@size) {
    .size(@size);
    border-radius: 50%;
}