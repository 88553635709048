@import '../less/sizes.less';
@import '../less/mixins.less';
@import '../less/colors.less';

@vm-transition-time: 0.3s;
@vm-transition-delay: 0.4s;

.VenstreMenyContainer {
    padding-top: var(--toppmeny-height);
    position: fixed;
    height: 100%;
    z-index: 100;
    display: flex;
    flex-direction: row;

    .VenstreMeny,
    .VenstreMenyOverlay {
        transition-property: margin-left, opacity;
        transition-duration: 0.4s;
        transition-timing-function: ease;
    }

    .VenstreMeny {
        #device.mobile( {
                overflow-y: auto;
            }

        );
        position: relative;
        background-color: @grey-base;
        color: #f0f2f2;
        width: @venstremeny-width;
        padding: 15px 0;
        padding-top: 35px;
        -webkit-overflow-scrolling: touch;

        #device.desktop( {
                width: 60px;
                transition: @vm-transition-time width ease-in-out;

                .menyitem-left > p, .menyitem-caret {
                    opacity: 0;
                    transition-property: opacity;
                    transition-duration: @vm-transition-time * (2 / 3);
                    transition-delay: @vm-transition-time * (1 / 3);
                }

                &:hover {
                    width: @venstremeny-width;

                    .menyitem-left > p, .menyitem-caret, .Pin {
                        opacity: 1;
                    }
                }

                &:not(:hover) {
                    transition-delay: @vm-transition-delay;

                    .menyitem-left > p, .menyitem-caret, .Pin {
                        transition-delay: @vm-transition-delay;
                    }
                }

                overflow-x: visible;

                &:not(:hover) {
                    overflow-x: hidden;
                }

                .HjelpItem {
                    display: none;
                }
            }

        );

        #device.desktop_menu-pinned( {
                width: @venstremeny-width;

                .menyitem-left > p, .menyitem-caret, .Pin {
                    opacity: 1;
                }
            }

        );
    }

    .VenstreMeny::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }

    .VenstreMenyOverlay {
        flex: 1;
        opacity: 0;
        background-color: fade(black, 50%);
        height: 100%;
    }

    #device.mobile( {
            padding-top: var(--toppmeny-mobile-height);
            width: 100%;

            .VenstreMenyOverlay {
                opacity: 1;
            }

            &.hide-mobile {
                .VenstreMeny {
                    margin-left: -@venstremeny-width;
                }

                .VenstreMenyOverlay {
                    opacity: 0;
                }

                pointer-events: none;
            }
        }

    );

    .Pin {
        color: inherit;
        text-decoration: inherit;
        @pin-size: 25px;
        position: absolute;
        top: 5px;
        right: 12.5px;
        height: @pin-size;
        width: @pin-size;
        border-radius: @pin-size / 2;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: @vm-transition-time opacity ease, 0.1s background-color ease;

        #device.mobile( {
                display: none;
            }

        );

        &:hover {
            background-color: fade(black, 25%);

            &.active {
                background-color: fade(black, 50%);
            }
        }

        &.active {
            background-color: fade(black, 25%);
        }

        &:active {
            .fa {
                transform: translateY(-1px);
            }
        }
    }
}
