@import '../less/sizes.less';
@import '../less/mixins.less';
@import '../less/colors.less';

@mobile: ~'only screen and (max-width: 768px)';

.ToppMenyBruker {
    position: relative;
    display: flex;
    place-items: center;

    > a {
        color: inherit;
        text-decoration: inherit;

        &:hover,
        &:active,
        &:focus {
            color: inherit;
            text-decoration: inherit;
        }
    }

    .avatar {
        width: 3.125rem;
        height: 3.125rem;
        cursor: pointer;

        @media @mobile {
            width: 2.75rem;
            height: 2.75rem;
        }
    }

    .BrukerBilde {
        img,
        div {
            display: block;
            height: 100%;
            width: 100%;
            border-radius: 999px;
        }

        div {
            border: 1px solid lightgray;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                color: white;
                margin: 0;
            }
        }
    }

    .brukerKnapp {
        cursor: pointer;
        user-select: none;
        height: var(--toppmeny-current-height);

        .BrukerBilde {
            height: var(--toppmeny-current-height);
            width: var(--toppmeny-current-height);
            padding: 7px;
        }
    }

    .PulldownWrapper {
        position: absolute;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        border-radius: 0 0 5px 5px;
        overflow: hidden;
        box-shadow: 0 1.5px 3px rgba(0, 0, 0, 0.3);
        z-index: -1;
        right: 0;
        width: @venstremeny-width * 1.618;
        top: var(--toppmeny-current-height);
        opacity: 1;
        transition: top 0.2s ease, opacity 0.2s ease;

        #device.small( {
                right: -@toppmeny-margin-lr;
                border-radius: 0;
                width: 100vw;
            }

        );

        .PulldownInnhold {
            background-color: #fafaf6;
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            box-shadow: 0px 11.5px 3px -10px rgba(0, 0, 0, 0.15) inset, 0px -11.5px 3px -10px rgba(0, 0, 0, 0.15) inset;
            transition: 0.2s height ease;

            #device.large( {
                    height: calc(2 * var(--toppmeny-height, @toppmeny-height) + 4 * @toppmeny-margin-lr);
                }

            );

            .InnholdVenstre {
                height: 100%;
                display: flex;
                align-items: center;
                padding: 2 * @toppmeny-margin-lr @toppmeny-margin-lr;

                .BrukerBilde {
                    width: calc(2 * var(--toppmeny-height, @toppmeny-height));
                    height: calc(2 * var(--toppmeny-height, @toppmeny-height));

                    p {
                        font-size: 40px;
                    }
                }
            }

            .InnholdHoyre {
                display: flex;
                flex-direction: column;

                .brukerAkt {
                    display: inline-block;
                    text-decoration: none;
                    font-size: 14px;
                    overflow: hidden;
                    border-radius: 5px;

                    span {
                        display: inline-block;
                        padding: 4px 10px;
                    }

                    .aktType {
                        background-color: #40b2be;
                        color: #fafaf6;
                    }

                    .aktNavn {
                        background-color: fade(black, 10%);
                        color: @grey-base;
                    }

                    &:hover {
                        .aktType {
                            background-color: mix(black, #40b2be, 10%);
                            color: #fafaf6;
                        }

                        .aktNavn {
                            background-color: mix(black, fade(black, 10%), 10%);
                            color: @grey-base;
                        }
                    }
                }
            }
        }

        .PulldownKnapper {
            height: calc(var(--toppmeny-current-height) * 0.618);
            background-color: @grey-base;
            display: flex;
            flex-direction: row;
            padding: 0 10px;
            justify-content: space-between;
            align-items: center;

            a {
                font-size: 16px;
                color: #fafaf6;
                cursor: pointer;
            }

            #device.small( {
                    a {
                        text-decoration: underline;
                    }
                }

            );
        }

        &.skjult {
            pointer-events: none;

            .PulldownInnhold {
                #device.large( {
                        height: 0px;
                    }

                );
            }

            top: 0;
            opacity: 0;
        }
    }
}
